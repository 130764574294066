
import { computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
// import CurrencyInput from "@/components/forms/CurrencyInput.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import {  Field, Form } from "vee-validate";
import Drawer from "@/components/shared/Drawer.vue"
import Extras from "./Extras.vue"
import AddProduct from "./AddProduct.vue"
import _ from "lodash";
import { getModule } from "vuex-module-decorators";
import NewProductEntryModule from "@/store/modules/productEntry/modules/new-product-entry";
import NewSaleModule from "@/store/modules/sales/modules/new-sales/index"; 
// import TaxesModule from "@/store/modules/taxes/modules/new-taxes/index"; 
import EditProductModal  from "./EditProductSales.vue";
import { SalesTypes } from "@/core/enums/sales-types";
import ApiService from "@/core/services/ApiService";
import ProductSale from "@/models/sales/ProductSale";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Validations from "yup";
import SaleDetail from "@/models/sales/SaleDetail";
import { UserTypes } from "@/core/enums/user-types";
import Select from "@/components/forms/Select.vue"
import hotkeys from "hotkeys-js";
import { useStore } from "vuex";
// import {VMoney} from 'v-money'


export default defineComponent({
    components:{
        ModuleMaster
        , Datatable
        , Datarow
        , Form
        , Drawer
        , Extras
        , AddProduct
        , SelectFilterRemote
        , Field
        , EditProductModal
        , Select
        // , CurrencyInput
    },
    props: {
       isActionAdd: {
           type: Number,
           default: SalesTypes.CreateSale
       }
    },
    setup(){
        // const money = ref(({//en algun momento se usara multilenguaje y aqui pueden aplicar los cambios
        //     decimal: '.',
        //     thousands: ',',
        //     prefix: '$ ',
        //     suffix: '',
        //     precision: 2,
        //     masked: false
        // }))
        //Propiedades
        const moduleProduct = getModule(NewProductEntryModule);
        // const rolesModule = getModule(RolesModule);
        const moduleNewSale = getModule(NewSaleModule);
        // const moduleTaxes = getModule(TaxesModule);
        const openModalProductForm = ref(HTMLButtonElement);
        const showSearchProduct = ref(false);
        const haveDiscont = ref(true);
        const formAdd = ref();
        const porcentDiscont = ref<any>([]); // Segun es este el de precios
        const store = useStore(); 

        const formDefaultValues = {
            id: "",
                name: "",
                brandId: "",
                brandName: "",
                code: "",
                conceptTypeId: "",
                cost: 0,
                createdAt: "",
                familyId: "",
                familyName: "",
                lineId: "",
                lineName: "",
                price: 0,
                publicPrice: 0,
                wholesalePrice: 0,
                buyerPrice: 0,
                maximunDiscount: 0,
                minimunDiscount: 0,
                discont: 0,
                qualityId: "",
                qualityName: "",
                sizeId: "",
                sizeName: "",
                status: true,
                stock:0,
                subFamilyId: "",
                subFamilyName: "",
                unitOfMeasurementId: "",
                unitOfMeasurementName: "",
                warehouseId: "",
                warehouseName: "",
                description: "",
                isInventory: false,
                taxValue: 0
        } as ProductSale;
        
        const productInfo = ref<ProductSale>(formDefaultValues);
                            
        const showExtras = ref(false);
        const showMoreInfo = ref(false);
        
        //Schema
        const validationSchema = Validations.object().shape({
            productId: Validations.string().required().default(null).label("productId"),
            price: Validations
                    .number()
                    .required()
                    .min(1, 'Precio minimo 1.')
                    .typeError('Ingrese una cantidad valida')
                    .label("price"),
            amount: Validations
                    .number()
                    .required()
                    .min(1, 'Ingrese una cantidad minima de 1')
                    .typeError('Ingrese una cantidad valida')
                    .label("amount"),
            dto: Validations
                .number()
                .min(0, `Minimo desuento 0`)
                .max(100, `Maximo descuento 100`)
                .typeError('Ingrese una cantidad valida')
                .label("dto")
        });


        //METODOS
        // moduleTaxes.SEARCH_INFO_TAXES("27871d37-f9d2-4c20-8103-74704c96303b").then((r) => {
        //     moduleNewSale.SAVE_TAX_VALUE(r.value);
        // });

        const handleSearchProducts = (name: string) => {
            if(name.trim().length >= 3){
                moduleProduct.SEARCH_PRODUCTS(name);
            }
        }

        const calculatePercentage = (price: number, amount: number, percentage: number) => {
            let result = 0;
            result = ((amount * price) * percentage) / 100;
            return parseFloat(result.toFixed(2));
        }

        const calculatePrice = (userType: number) => {

            if(canSalePriceFree.value || !productInfo.value.isInventory){
                productInfo.value.price = userType; 
            }else{
                switch(userType){
                    case UserTypes.Buyer:
                        productInfo.value.price = productInfo.value.buyerPrice; 
                        break;
                    case UserTypes.Public:
                        productInfo.value.price = productInfo.value.publicPrice; 
                        break;
                    case UserTypes.Wholesale:
                        productInfo.value.price = productInfo.value.wholesalePrice; 
                        break;
                    default:
                        // productInfo.value.price = productInfo.value.price; 
                        break;
                }
            }

            return parseFloat(productInfo.value.price.toFixed(2));
        }

        const calculatePriceByInput = (userType: UserTypes = 0) => {
            console.log(userType)

            let price = 0;
            switch(userType){
                case UserTypes.Buyer:
                    price = parseFloat(productInfo.value.buyerPrice.toFixed(2)); 
                    break;
                case UserTypes.Public:
                    price =  parseFloat(productInfo.value.publicPrice.toFixed(2)); 
                    break;
                case UserTypes.Wholesale:
                    price = parseFloat(productInfo.value.wholesalePrice.toFixed(2)); 
                    break;
                default:
                    // productInfo.value.price = productInfo.value.price; 
                    break;
            }

            return parseFloat(price.toFixed(4));
        }

        const submitForm = (product: any) =>{

            const index = moduleNewSale.getSaleDetailAll.find(d => d.productId === product.productId);

            // const priceProduct= calculatePrice(product.price);
            const discontPersent = calculatePercentage(product.price, product.amount, product?.dto ?? 0 );

            const textRow = parseFloat((((product.amount * product.price) - (discontPersent)) * (productInfo.value.taxValue / 100)).toFixed(2));
            const saleDetail = {
                id: index ? index.id : '00000000-0000-0000-0000-000000000000',
                productId: productInfo.value.id,
                code: productInfo.value.code,
                productName: productInfo.value.name,
                unitOfMeasurementName: productInfo.value.unitOfMeasurementName,
                unitOfMeasurementId: productInfo.value.unitOfMeasurementId,
                price: product.price,
                amount: product.amount,
                discount: parseFloat((discontPersent).toFixed(2)), //(precio del producto * cantidad)
                saleId: index ? index.saleId : infoSale.value.id, 
                total: parseFloat(((product.amount * product.price) - (discontPersent)).toFixed(2)) + textRow,//parseFloat((product.amount * product.price).toFixed(2)) + textRow, //(Cantidad *  Precio)
                import: parseFloat(((product.amount * product.price) - (discontPersent)).toFixed(2)),
                subTotal: parseFloat(((product.amount * product.price) - (discontPersent)).toFixed(2)),
                tax: textRow, //parseFloat((((product.amount * product.price) - (discontPersent)) * (productInfo.value.taxValue / 100)).toFixed(2)),  // tax --> (importe * (porcentaje de iva / 100)),
                discountPersent: product?.dto ?? 0,
                taxPersent: productInfo.value.taxValue,
                // wholesalePrice: index?.wholesalePrice,
                wholesalePrice: productInfo.value.wholesalePrice,
                publicPrice: productInfo.value.wholesalePrice,
                buyerPrice: productInfo.value.buyerPrice,
                maximunDiscount: productInfo.value.maximunDiscount,
                minimunDiscount: productInfo.value.minimunDiscount 
                , unit: ''
                , status: index ? index.status : true
                , stock: productInfo.value.stock
            } as SaleDetail;
            
            if(index){
               
               if(index.status){
                    Swal.fire({
                    title: `Este producto se encuentra en la venta`,
                    text: `¿Desea agregar la cantidad al producto?`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, agregar',
                    cancelButtonText: 'Cancelar'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        moduleNewSale.DELETE_PRODUCT(index.productId);
                        
                        const newAmount:number = parseInt(product.amount) + parseInt(index.amount.toString());
                        const taxRow = parseFloat((((newAmount * product.price) - (discontPersent)) * (productInfo.value.taxValue / 100)).toFixed(2));
                        saleDetail.amount = newAmount;
                        saleDetail.discount = parseFloat((discontPersent).toFixed(2));
                        saleDetail.total = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2)) + taxRow,//(parseFloat((newAmount * product.price).toFixed(2)) + taxRow);
                        saleDetail.subTotal = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2));
                        saleDetail.import = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2));
                        saleDetail.tax = taxRow,//parseFloat((((newAmount * product.price) - (discontPersent)) * (productInfo.value.taxValue / 100)).toFixed(2));
                        saleDetail.status = true;
                        
                        moduleNewSale.ADD_PRODUCT(saleDetail);
                        
                        productInfo.value = formDefaultValues;
                        formAdd.value.resetForm();
                        showMoreInfo.value = false;
                        // sucessAddProduct();
                    }
              });
               }else{
                        moduleNewSale.DELETE_PRODUCT(index.productId);
                        
                        const newAmount:number = parseInt(product.amount);
                        const taxtRow = parseFloat((((newAmount * product.price) - (discontPersent)) * (productInfo.value.taxValue/ 100)).toFixed(2));
                        saleDetail.amount = newAmount;
                        saleDetail.discount = parseFloat((discontPersent).toFixed(2));
                        saleDetail.total = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2)) + taxtRow,//(parseFloat((newAmount * product.price).toFixed(2)));
                        saleDetail.subTotal = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2));
                        saleDetail.import = parseFloat(((newAmount * product.price) - (discontPersent)).toFixed(2));
                        saleDetail.tax = parseFloat((((newAmount * product.price) - (discontPersent)) * (productInfo.value.taxValue/ 100)).toFixed(2));
                        saleDetail.status = true;
                        
                        moduleNewSale.ADD_PRODUCT(saleDetail);
                        
                        productInfo.value = formDefaultValues;
                        formAdd.value.resetForm();
                        showMoreInfo.value = false;
                        // sucessAddProduct();
               }
               
            }else{
                moduleNewSale.ADD_PRODUCT(saleDetail);
                
                productInfo.value = formDefaultValues;
                
                formAdd.value.resetForm();
                showMoreInfo.value = false;

                // sucessAddProduct();
            }
            resetProduct();
        }

        const resetProduct = () => {
            haveDiscont.value = true;
            porcentDiscont.value = [];
            productInfo.value.isInventory = false;
            
            validationSchema.fields.dto = Validations
                                            .number()
                                            .min(0, `Minimo desuento 0`)
                                            .max(100, `Maximo descuento 100`)
                                            .typeError('Ingrese una cantidad valida')
                                            .label("dto");
        }

        const handleDeleteProduct = (id: string) => {
            const row = moduleNewSale.getSaleDetailAll.find(d => d.productId === id);

            if(row){
                moduleNewSale.DELETE_PRODUCT(id);
                
                moduleNewSale.UPDATE_PRODUCT({
                    id: row ? row.id : '',
                    productId: row.productId,
                    code: row.code,
                    productName: row.productName,
                    unitOfMeasurementName: row.unitOfMeasurementName,
                    unitOfMeasurementId: row.unitOfMeasurementId,
                    price: row.price,
                    amount: row.amount,
                    discount: row.discount, 
                    saleId: row.saleId, 
                    total: row.total, 
                    import: row.import,
                    subTotal: row.subTotal,
                    tax: row.tax,  
                    discountPersent: row.discountPersent,
                    buyerPrice:0,
                    maximunDiscount:0,
                    minimunDiscount: 0,
                    publicPrice: 0,
                    wholesalePrice: 0,
                    taxPersent: row.taxPersent
                    , unit: row.unit
                    , status: false
                    ,isInventory: false
                    , stock: row.stock
                    // ,taxValue: row.taxPersent
                });
            }else{
                moduleNewSale.DELETE_PRODUCT(id);
            }

        }

        const handleEditProduct = async (id: string) => {
            const index = rowDetail.value.findIndex(d => d.productId === id);

            // console.log(index);
            
            if (index > -1) {
                var row = rowDetail.value.find(d => d.productId === id);
                
                if(row){
                    let url = `Products/${row?.productId}`;
                    var res = await ApiService.get(url);

                    var product = res.data as ProductSale;
                    console.log(product)
                    
                    row.minimunDiscount = product.minimunDiscount;
                    row.maximunDiscount = product.maximunDiscount;
                    row.isInventory = product.isInventory;
                    row.publicPrice = product.publicPrice;
                    row.wholesalePrice = product.wholesalePrice;
                    row.buyerPrice = product.buyerPrice;
                    row.stock = product.stock;

                    if(openModalProductForm.value && row){
                        moduleNewSale.SAVE_EDIT_PRODUCT(row);
                        (openModalProductForm.value as unknown as HTMLButtonElement).click();
                    }
                }
            }
        }

         /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "productId", HeadLabel: "productId", Type: "text", VisibleInGrid: false },
            { PropName: "code", HeadLabel: "Codigo", Type: "text", VisibleInGrid: false },
            { PropName: "productName", HeadLabel: "Descripción", Type: "text", VisibleInGrid: true },
            { PropName: "unitOfMeasurementName", HeadLabel: "Unidad", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Cantidad", Type: "text", VisibleInGrid: true },
            { PropName: "price", HeadLabel: "Precio", Type: "moneda", VisibleInGrid: true },            
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },            
            { PropName: "discountPersent", HeadLabel: "Dcto", Type: "percentage", VisibleInGrid: true },            
            { PropName: "discount", HeadLabel: "Dcto", Type: "moneda", VisibleInGrid: true },            
            { PropName: "subTotal", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },            
        ]);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Boton prueba",
        });
        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });


        const renderRows = computed(() => {
            return formatToDatatable(moduleNewSale.getSaleDetail, configTable, "", "productId");
        });

        const rowDetail = computed(() => moduleNewSale.getSaleDetail);
        const productsCombo = computed(() => moduleProduct.productOptions);
        const productsLoading = computed(() => moduleProduct.loadings.productSelect);
        // const taxesInfo = computed(() => moduleTaxes.getTaxeInfo);
        const clientsCombo = computed(() => moduleNewSale.getClientOptions);
        const infoSale = computed(() => moduleNewSale.getValuesForm);
        // const roleUser = computed(() => rolesModule.canSalePriceFree);
        
        const searchProduct = () => {
            console.log("buscar cliente");
        }

        const changeSearchProduct = () => {
            showSearchProduct.value = true;
        }

        const changeProduct = _.throttle(async (idSelected: string) => {
            haveDiscont.value = true;

            const idClient = moduleNewSale.idClientSelected;
            let typeCustomer;

            try {
                let url = `Customer/GetTypeCustomer/${idClient}`;
                const res = await ApiService.query(url);

                typeCustomer = res.data;
            } catch (error) {
                typeCustomer = 0;
            }

            if(idSelected != ""){
                resetProduct(); 
                let url = `Products/${idSelected}`;
                ApiService.get(url).then(res => {
                    if(res.data) {
                        productInfo.value = res.data as ProductSale;
                        // console.log(productInfo.value)
                        // if(canSalePriceFree.value){
                            porcentDiscont.value.push({
                                id:  productInfo.value.publicPrice,
                                name: productInfo.value.publicPrice.toFixed(2)
                            });

                            porcentDiscont.value.push({
                                id: productInfo.value.wholesalePrice, //productInfo.value.wholesalePrice > 0 ? 1 : 0,
                                name: productInfo.value.wholesalePrice.toFixed(2)
                            });

                            porcentDiscont.value.push({
                                id: productInfo.value.buyerPrice,//productInfo.value.buyerPrice > 0 ? 2 : 0,
                                name: productInfo.value.buyerPrice.toFixed(2)
                            });
                                //
                            console.log(porcentDiscont.value)
                        //}
                        if(canSalePriceFree.value){
                            validationSchema.fields.dto = Validations
                                             .number()
                                             .min(0, `Minimo desuento 0`)
                                             .max(100, `Maximo descuento 100`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                            haveDiscont.value = false;
                        }
                        else{
                            if(productInfo.value.maximunDiscount > 0 && !canSalePriceFree.value){
                            validationSchema.fields.dto = Validations
                                             .number()
                                             .min(productInfo.value.minimunDiscount, `Minimo desuento ${productInfo.value.minimunDiscount}`)
                                             .max(productInfo.value.maximunDiscount, `Maximo descuento ${productInfo.value.maximunDiscount}`)
                                             .typeError('Ingrese una cantidad valida')
                                             .required()
                                             .label("dto");

                            haveDiscont.value = false;
                        }

                        if(productInfo.value.maximunDiscount > 0){
                            haveDiscont.value = false;
                        }
                    }
                        // else{
                        //     if(productInfo.value.minimunDiscount > 0){
                        //         haveDiscont.value = false;
                        //     }

                        //     validationSchema.fields.dto = Validations
                        //                      .number()
                        //                      .min(0, `Minimo desuento ${productInfo.value.minimunDiscount}`)
                        //                      .max(1, `Maximo descuento ${productInfo.value.maximunDiscount}`)
                        //                      .typeError('Ingrese una cantidad valida')
                        //                      .required()
                        //                      .label("dto");
                        // }


                        validationSchema.fields.amount = Validations
                                                    .number()
                                                    .required()
                                                    .min(1, 'Ingrese una cantidad minima de 1')
                                                    .max(productInfo.value.stock, `Ingrese una cantidad minima de ${productInfo.value.stock}`)
                                                    .typeError('Ingrese una cantidad valida')
                                                    .label("amount");


                        // const priceUser = 0;


                        const client = clientsCombo.value.find(ir => ir.id === idClient);



                        const priceDefault = calculatePriceByInput(typeCustomer);

                        formAdd.value.setFieldValue('productId', idSelected);
                        formAdd.value.setFieldValue('dto',  productInfo.value.minimunDiscount);
                        
                        if(canSalePriceFree.value || productInfo.value.isInventory){
                            formAdd.value.setFieldValue('price', priceDefault);
                        }

                        if(!productInfo.value.isInventory){
                             validationSchema.fields.amount = Validations
                                                    .number()
                                                    .required()
                                                    .min(1, 'Ingrese una cantidad minima de 1')
                                                    .typeError('Ingrese una cantidad valida')
                                                    .label("amount");
                        }

                        if(client){
                            formAdd.value.setFieldValue('price', priceDefault);
                        }

                        showMoreInfo.value = true;
                    }            
                }).catch(reject => {
                console.log(reject);
                });
            }
        });

        const clearProduct = _.throttle(() => {
                showMoreInfo.value = false;
                productInfo.value = formDefaultValues;
        });

        const canSalePriceFree = computed(() => store.getters['canSalePriceFree']);

        onMounted(() => {
                hotkeys('alt+b', (event, handler) => {
                   changeSearchProduct();
                });
        });

        onBeforeUnmount(() => {
                hotkeys.unbind('alt+b');
        });
        
         return {
            headers
            ,canSalePriceFree
            // , roleUser
            , showMoreInfo
            , renderRows
            , labels
            , showSearchProduct
            , showExtras
            , searchProduct
            , clearProduct
            // , money

            , changeProduct
            , calculatePriceByInput
            , productInfo
            , productsCombo
            , productsLoading
            , openModalProductForm
            , rowDetail
            , SalesTypes
            , UserTypes
            , validationSchema
            , formAdd
            // , taxesInfo
            , clientsCombo
            , infoSale
            , porcentDiscont
            , haveDiscont
            
            , handleSearchProducts
            , calculatePercentage
            , submitForm
            , handleEditProduct
            , handleDeleteProduct
            , changeSearchProduct
            , calculatePrice
            , resetProduct
            // , sucessAddProduct
         }
    }
    // , directives:{
    //     money: VMoney
    // }
})
